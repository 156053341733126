<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showform"
        max-width="1400px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title class="mb-6">
            Nouveau Bon d'expedition composé
            <v-spacer></v-spacer>
            <div class="mx-2 mt-4">
              <cursorselect
                :Qsearch="tierscursor"
                :Qresp="'tiers_cursor'"
                :value="tier.tier_id"
                :text_fields="['code_client', 'nom']"
                :variableadd="variableadd"
                :item_text="'tier_name'"
                @change="tierChange"
                :label="'Client'"
              >
              </cursorselect>
            </div>
            <v-divider vertical></v-divider>
            <div class="mx-2 mt-4" v-if="tabs == 1 && mvms_list.length > 0">
              <datepicker
                label="Date Opération"
                v-model="editedItem.opr_date"
                :rules="[(v) => !!v || 'Date Opération obligatoire']"
                :edit="true"
                :date_max="$store.state.today"
              ></datepicker>
            </div>
            <v-divider vertical></v-divider>
            <v-btn
              color="blue darken-1"
              text
              @click.stop="save"
              v-if="tabs == 1"
              :disabled="reste_list.length == 0"
            >
              <v-icon left> mdi-content-save-all </v-icon>
              Créer
            </v-btn>
            <v-divider vertical></v-divider>
            <v-btn class="mx-2" color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-title>

          <v-tabs right v-model="tabs" class="pa-4" @change="tabs_change">
            <v-tab> Articles Encours </v-tab>
            <v-tab v-if="mvms_list.length > 0">
              Nouveau Bon d'expedition
            </v-tab>
          </v-tabs>

          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sd="2"
                md="2"
                lg="2"
                v-if="tabs == 1 && mvms_list.length > 0"
              >
                <v-text-field
                  autocomplete="off"
                  dense
                  class="inputPrice"
                  v-model="editedItem.mvm_ref"
                  label="Ref."
                  @focus="$event.target.select()"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sd="4"
                md="4"
                lg="4"
                class="text-left"
                v-if="tabs == 1 && mvms_list.length > 0"
              >
                <v-text-field
                  dense
                  v-model="editedItem.comment"
                  label="Commentaire"
                  @focus="$event.target.select()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sd="12" md="12">
                <listitems
                  :list="tabs == '0' ? new_list : reste_list"
                  :headers="products_headers"
                  :add="false"
                  :del="false"
                  :master="true"
                  :Update="true"
                  :showedit="true"
                  :showstd="true"
                  :Total="true"
                  @rowselect="SelectItem"
                  @open="open_list"
                  :exp_excel="true"
                  :key="klist"
                  :laoding="art_loading"
                >
                </listitems>
              </v-col>
              <v-col cols="12" sd="7" md="7" lg="7"></v-col>

              <v-col
                cols="12"
                sd="4"
                md="4"
                lg="4"
                v-if="mvms_list.length > 0 && tabs == 1"
              >
                <v-row no-gutters>
                  <v-col cols="12" sd="6" md="4"> Total H.T : </v-col>
                  <v-col
                    cols="12"
                    sd="6"
                    md="8"
                    class="text-bold nowrap text-right pa-1"
                  >
                    {{ numberWithSpace(decimalDigits(subTotal_r)) }}
                  </v-col>
                </v-row>
                <div>
                  <v-row
                    v-for="(tax, taux) in taxTotal_r"
                    :key="taux"
                    no-gutters
                  >
                    <v-col cols="12" sd="6" md="4">
                      T.V.A {{ tax.taux }} %
                    </v-col>
                    <v-col cols="12" sd="6" md="8" class="text-right pa-1">
                      {{ numberWithSpace(decimalDigits(tax.tva)) }}
                    </v-col>
                  </v-row>
                  <v-row v-if="taxTotal_r.length > 1" no-gutters>
                    <v-col cols="12" sd="6" md="4"> Total T.V.A </v-col>
                    <v-col cols="12" sd="6" md="8" class="text-right pa-1">
                      {{ numberWithSpace(decimalDigits(sumtaxTotal_r("tva"))) }}
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="subTotal_r > 0 && sumtaxTotal_r('tva') == 0"
                    no-gutters
                  >
                    <v-col cols="12" sd="6" md="4"> T.V.A</v-col>
                    <v-col
                      cols="12"
                      sd="6"
                      md="8"
                      class="text-bold text-right pa-1"
                    >
                      EXONORE
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sd="6" md="4"> Total T.T.C </v-col>
                    <v-col
                      cols="12"
                      sd="6"
                      md="8"
                      class="text-bold nowrap text-right pa-1"
                    >
                      {{ numberWithSpace(decimalDigits(grandTotal_r)) }}
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-form>
    <v-alert v-model="alert_value" :type="type_alert" dismissible>
      {{ message }}
    </v-alert>
    <listdetail
      :item="article"
      :showForm="!isDetailClose"
      :key="kdet"
      @close="closeDetail"
      @save="save_detail"
    >
    </listdetail>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <confirmdialog ref="confirm" />
  </v-container>
</template>
<script>
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
import MVMS_DETAIL from "../graphql/Mouvment/MVMS_DETAIL.gql";
import MVM_SCINDER_UPSERT from "../graphql/Mouvment/MVM_SCINDER_UPSERT.gql";
import TIERS_CURSOR from "../graphql/Commande/TIERS_CURSOR.gql";
export default {
  name: "formlist",
  components: {
    listitems: () => import("./ListItems.vue"),
    listdetail: () => import("./FormScinderDetail.vue"),
    confirmdialog: () => import("./ConfirmDialog.vue"),
    datepicker: () => import("./DatePicker.vue"),
    cursorselect: () => import("./CursorSelect.vue"),
  },
  props: {
    showform: { type: Boolean, default: false },
  },
  data() {
    return {
      fermer: "Fermer",
      type_alert: "info",
      message: "",
      alert: false,
      change: 0,
      valid: true,
      items: [],
      new_list: [],
      reste_list: [],
      klist: 100,
      fl: 200,
      kdet: 300,
      stock: false,
      progress: false,
      isDetailClose: true,
      items_list: [],
      colissheaders: [],
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      article: {},
      editedItem: {},
      tabs: "0",
      mvms_list: [],
      products_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "Ref.",
          value: "nobon",
          selected: true,
          align: "left",
        },
        {
          text: "Date",
          value: "mvm_date",
          selected: true,
          slot: "date",
          align: "left",
        },
        {
          text: "Code",
          value: "code",
          selected: true,
          align: "left",
        },
        {
          text: "Produit",
          value: "label",
          selected: true,
          width: "300",
          align: "left",
        },

        {
          text: "PU HT",
          value: "pu",
          selected: true,
        },

        {
          text: "Quantité",
          value: "qte",
          slot: "href22",
          align: "end",
          selected: true,
          total: "qte",
          edit: true,
          type: "qte",
          oblig: false,
          dec: 2,
          max_qte: true,
          sm: 12,
          md: 6,
        },
        {
          text: "Unité",
          value: "unit",
          selected: true,
        },

        {
          text: "Montant HT",
          value: "ht",
          slot: "cur",
          align: "end",
          selected: true,
          total: "ht",
        },
        {
          text: "Comment",
          value: "comment",
          selected: false,
        },
        {
          text: "Colisage",
          value: "coliscount",
          slot: "cur",
          align: "end",
          dec: 0,
          selected: true,
        },
      ],
      tier: {},
      art_loading: false,
    };
  },
  async mounted() {},
  watch: {
    new_list: {
      handler() {
        if (this.article.bylist == "0") {
          let new_qte = 0;
          let i = this.new_list.findIndex((elm) => elm.id == this.article.id);
          if (i >= 0) {
            new_qte = this.new_list[i].qte;
            this.new_list[i].ht = parseFloat(
              (this.new_list[i].qte * this.new_list[i].pu).toFixed(2)
            );
          }
          i = this.reste_list.findIndex((elm) => elm.id == this.article.id);
          if (i >= 0) {
            this.reste_list[i].qte = this.article.qte_max - new_qte;
            this.reste_list[i].ht = parseFloat(
              (this.reste_list[i].qte * this.reste_list[i].pu).toFixed(2)
            );
          } else {
            let new_article = Object.assign({}, this.article);
            new_article.qte = this.article.qte_max - new_qte;
            new_article.ht = parseFloat(
              (new_article.qte * new_article.pu).toFixed(2)
            );
            this.reste_list.push(new_article);
          }
        }
      },
    },
  },
  computed: {
    alert_value: {
      get: function () {
        return this.alert;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    exe_tva: function () {
      return this.tier ? this.tier.exe_tva : 0;
    },
    variableadd() {
      let w = {
        TypeScope: 2,
        statut_id: [2, 3],
        Kind: 0,
      };
      return w;
    },
    tierscursor() {
      return TIERS_CURSOR;
    },
    subTotal_r: function () {
      let l = this.reste_list;
      var total = l
        ? l.reduce(function (accumulator, item) {
            return (
              accumulator +
              parseFloat(
                ((item.pu - (item.pu * item.remise) / 100) * item.qte).toFixed(
                  2
                )
              )
            );
          }, 0)
        : 0;
      return total;
    },
    remise_r: function () {
      let l = this.reste_list;
      var total = l
        ? l.reduce(function (accumulator, item) {
            return (
              accumulator +
              parseFloat(
                (((item.pu * item.remise) / 100) * item.qte).toFixed(2)
              )
            );
          }, 0)
        : 0;
      return total;
    },
    taxTotal_r: function () {
      let l = this.reste_list;
      let taxs = [];
      if (l) {
        l.forEach((element) => {
          if (element.tva_tx && (this.exe_tva == "0" || this.exe_tva == null)) {
            let v =
              ((element.pu - (element.pu * element.remise) / 100) *
                element.qte *
                element.tva_tx) /
              100;
            let i = taxs.findIndex((elm) => elm.taux == element.tva_tx);
            if (i >= 0) taxs[i].tva = parseFloat(taxs[i].tva) + parseFloat(v);
            else
              taxs.push({
                taux: element.tva_tx,
                tva: v,
                exe_tva: this.exe_tva,
              });
          }
        });
      }
      return taxs;
    },
    grandTotal_r: function () {
      let taxs = 0;
      if (this.exe_tva == "0" || this.exe_tva == null)
        this.taxTotal_r.forEach((element) => {
          taxs = taxs + parseFloat(element.tva);
        });
      var total = parseFloat(this.subTotal_r) + taxs;
      return total;
    },
    linked() {
      let ok = false;
      if (this.article.links) ok = this.article.links.length > 0;
      return ok;
    },
  },
  methods: {
    async tierChange(item, l) {
      this.new_list = [];
      this.mvms_list = [];
      let list = []; // this.tiers_list;
      if (l) list = l;
      if (item) this.tier.tier_id = item;

      let i = list.findIndex((elm) => elm.id == this.tier.tier_id);

      if (i >= 0) {
        this.tier = list[i];
        let v = {
          tier_id: this.tier.id,
          nofact: 1,
        };
        this.art_loading = true;
        let r = await this.requette(MVMS_DETAIL, v);
        this.art_loading = false;
        if (r) {
          this.mvms_list = r.mvms_detail;

          if (this.mvms_list) {
            this.mvms_list.forEach((element) => {
              element.changed = false;
              element.qte_max = element.qte;
              element.qte_min = 0;
              this.new_list.push(element);
            });
            this.klist++;
          }
        }
      }
    },

    subTotal_o(list) {
      var total = list
        ? list.reduce(function (accumulator, item) {
            return (
              accumulator +
              parseFloat(
                ((item.pu - (item.pu * item.remise) / 100) * item.qte).toFixed(
                  2
                )
              )
            );
          }, 0)
        : 0;
      return total;
    },
    remise_o(list) {
      var total = list
        ? list.reduce(function (accumulator, item) {
            return (
              accumulator +
              parseFloat(
                (((item.pu * item.remise) / 100) * item.qte).toFixed(2)
              )
            );
          }, 0)
        : 0;
      return total;
    },
    taxTotal_o(list) {
      let taxs = [];
      if (list) {
        list.forEach((element) => {
          if (element.tva_tx && (this.exe_tva == "0" || this.exe_tva == null)) {
            let v =
              ((element.pu - (element.pu * element.remise) / 100) *
                element.qte *
                element.tva_tx) /
              100;
            let i = taxs.findIndex((elm) => elm.taux == element.tva_tx);
            if (i >= 0) taxs[i].tva = parseFloat(taxs[i].tva) + parseFloat(v);
            else
              taxs.push({
                taux: element.tva_tx,
                tva: v,
                exe_tva: this.exe_tva,
              });
          }
        });
      }
      return taxs;
    },
    grandTotal_o(list) {
      let taxs = 0;
      if (this.exe_tva == "0" || this.exe_tva == null)
        this.taxTotal_o(list).forEach((element) => {
          taxs = taxs + parseFloat(element.tva);
        });
      var total = parseFloat(this.subTotal_o(list)) + taxs;
      return total;
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    sumlist_selected(form_list, d) {
      let list = [];
      list = form_list ? form_list : [];
      let v_min = 0;

      if (this.linked) {
        let l = [];
        this.article.links.forEach((element) => {
          let v =
            list
              .filter((elm) => elm.article_id == element.link_id)
              .reduce((a, b) => a + (b["qte"] || 0), 0) / element.ratio;
          l.push(v / element.ratio);
        });
        v_min = Math.min(...l);
      } else v_min = list.reduce((a, b) => a + (b["qte"] || 0), 0);
      return parseFloat(v_min.toFixed(d));
    },
    save_detail(newlist, rest_list, newdescription, olddescription) {
      let i = this.new_list.findIndex((elm) => elm.id == this.article.id);
      if (i >= 0) {
        this.new_list[i].qte = this.sumlist_selected(
          newlist,
          this.article.decimal
        );
        this.new_list[i].changed =
          this.new_list[i].qte != this.new_list[i].qte_max;
        this.new_list[i].ht = parseFloat(
          (this.new_list[i].qte * this.new_list[i].pu).toFixed(2)
        );
        this.new_list[i].colis_list = newlist;
        this.new_list[i].coliscount = newlist.length;
        this.new_list[i].description = olddescription;
      }
      i = this.reste_list.findIndex((elm) => elm.id == this.article.id);
      if (i >= 0) {
        this.reste_list[i].qte = this.sumlist_selected(
          this.reste_list,
          this.article.decimal
        );
        this.reste_list[i].ht = parseFloat(
          (this.reste_list[i].qte * this.reste_list[i].pu).toFixed(2)
        );
        this.reste_list[i].colis_list = rest_list;
        this.reste_list[i].coliscount = rest_list.length;
        this.reste_list[i].description = newdescription;
      } else {
        let new_article = Object.assign({}, this.article);
        new_article.qte = this.sumlist_selected(rest_list, new_article.decimal);
        new_article.ht = parseFloat(
          (new_article.qte * new_article.pu).toFixed(2)
        );
        new_article.colis_list = rest_list;
        new_article.coliscount = rest_list.length;
        new_article.description = newdescription;
        this.reste_list.push(new_article);
      }
      this.isDetailClose = true;
      this.klist++;
    },
    open_list(item) {
      this.article = item;
      this.isDetailClose = false;
      this.kdet++;
    },
    tabs_change() {
      this.klist++;
      this.products_headers[6].slot = this.tabs == "0" ? "href22" : "cur";
    },
    SelectItem(item) {
      this.article = item;
      this.$emit("rowselect", item);
    },
    closeDetail() {
      this.isDetailClose = true;
    },
    close() {
      this.$emit("close", this.subTotal_r);
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = { ok: true, data: data.data };
          this.progress = false;
        })
        .catch((error) => {
          r = { ok: false, error: error };
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.change = 0;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir créer cette nouvelle expedition?"
          )
        ) {
          let elm = {};
          //update old expeditions
          this.snackbar_text =
            "Mise a jour des expeditions actuelles et creation d'une nouvelle expedition";
          this.snackbar_color = "info";
          this.snackbar = true;
          let mvm = [];
          for (let index = 0; index < 2; index++) {
            if (index == 0) {
              var old_list = this.new_list
                .filter((elm) => elm.changed)
                .map((elm) => elm.mvm_id)
                .filter(onlyUnique);
              let detail = [];
              old_list.forEach((element1) => {
                let list = this.new_list.filter(
                  (elm) => elm.mvm_id == element1
                );
                list.forEach((element) => {
                  let products_list = [];
                  if (element.bylist == "1") {
                    element.colis_list.forEach((element) => {
                      elm = {
                        fk_produit_list: element.fk_produit_list,
                        qte: element.qte,
                      };
                      products_list.push(elm);
                    });
                  }
                  detail.push({
                    id: element.id,
                    qte: parseFloat(element.qte),
                    description: element.description,
                    products_list:
                      products_list.length > 0 ? products_list : null,
                  });
                });
                mvm.push({
                  id: element1,
                  mont_ht: this.subTotal_o(list),
                  mont_tva: this.sumtaxTotal_o(list),
                  mont_ttc: this.grandTotal_o(list),
                  mont_remise: this.remise_o(list),
                  detail: detail,
                });
              });
            } else {
              let detail = [];
              this.reste_list.forEach((element) => {
                let products_list = [];
                if (element.bylist == "1") {
                  element.colis_list.forEach((element) => {
                    elm = {
                      fk_produit_list: element.fk_produit_list,
                      qte: element.qte,
                    };
                    products_list.push(elm);
                  });
                }
                detail.push({
                  pu: parseFloat(element.pu),
                  qte: parseFloat(element.qte),
                  tva_tx: element.tva_tx,
                  fk_unit: element.fk_unit,
                  remise: parseFloat(element.remise),
                  label: element.label,
                  description: element.description,
                  depot_id: element.depot_id,

                  article_id: element.article_id,
                  cmd_det_id: element.cmd_det_id,
                  destock: element.destock,
                  comment: element.comment,
                  write_uid: this.$store.state.me.id,
                  products_list:
                    products_list.length > 0 ? products_list : null,
                });
              });

              mvm.push({
                mvm_type: 3,
                mvm_ref: this.editedItem.mvm_ref,
                tier_id: this.tier.id,
                mvm_date: this.editedItem.opr_date,
                opr_date: this.editedItem.opr_date,
                comment: this.editedItem.comment,
                statut_id: 11,

                modif_by: null,
                modif_date: null,
                mont_ht: this.subTotal_r,
                mont_tva: this.sumtaxTotal_r("tva"),
                mont_ttc: this.grandTotal_r,
                mont_remise: this.remise_r,
                detail: detail,
                write_uid: this.$store.state.me.id,
              });
            }
          }

          let v = {
            mvm: mvm,
          };

          let r = await this.maj(MVM_SCINDER_UPSERT, v);
          if (r.ok) {
            this.$emit("change", this.editedItem);
            this.$store.dispatch("Changed", true);
            this.snackbar_text =
              "Operation effectuée avec success N° nouveau bon " +
              r.data.UpsertMvmScinder;
            this.snackbar_color = "success";
            this.snackbar = true;
            this.$emit("close");
          } else {
            this.snackbar_text = "Erreur d'enregistrement inconnue ";
            this.snackbar_color = "error";
            this.snackbar = true;
            this.save_disable = false;
          }
        }
      }
    },

    decimalDigits: function (value) {
      return typeof value == "number" ? value.toFixed(2) : 0;
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    sumtaxTotal_o(list) {
      // sum data in give key (property)
      return this.taxTotal_o(list)
        ? this.taxTotal_o(list).reduce((a, b) => a + (b["tva"] || 0), 0)
        : 0;
    },
    sumtaxTotal_r(key) {
      // sum data in give key (property)
      return this.taxTotal_r
        ? this.taxTotal_r.reduce((a, b) => a + (b[key] || 0), 0)
        : 0;
    },
  },
};
</script>
